/**
 * Abstracts: MIXINS
 * ==============================
 */

// @include box-shadow(TOP, LEFT, BLUR, COLOR, SPREAD, INSET))
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $spread $color;
        -moz-box-shadow: inset $top $left $blur $spread $color;
        box-shadow: inset $top $left $blur $spread $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $spread $color;
        -moz-box-shadow: $top $left $blur $spread $color;
        box-shadow: $top $left $blur $spread $color;
    }
}

@mixin linearGradient($top, $bottom) {
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, $top),
        color-stop(100%, $bottom)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        top,
        $top 0%,
        $bottom 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
        top,
        $top 0%,
        $bottom 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

/// Hides an element visually while still allowing the content to be accessible
/// to assistive technology, e.g. screen readers. Passing `unhide` will reverse
/// the affects of the hiding, which is handy for showing the element on focus,
/// for example.
///
/// @link https://goo.gl/Vf1TGn
///
/// @argument {string} $toggle [hide]
///   Accepts `hide` or `unhide`. `unhide` reverses the affects of `hide`.
///
/// @example scss
///   .element {
///     @include hide-visually;
///
///     &:active,
///     &:focus {
///       @include hide-visually("unhide");
///     }
///   }
///
///   // CSS Output
///   .element {
///     border: 0;
///     clip: rect(1px, 1px, 1px, 1px);
///     clip-path: inset(100%);
///     height: 1px;
///     overflow: hidden;
///     padding: 0;
///     position: absolute;
///     width: 1px;
///   }
///
///   .hide-visually:active,
///   .hide-visually:focus {
///     clip: auto;
///     clip-path: none;
///     height: auto;
///     overflow: visible;
///     position: static;
///     width: auto;
///   }
///
/// @since 5.0.0

@mixin hide-visually($toggle: "hide") {
    @if not index("hide" "unhide", $toggle) {
        @error "`#{$toggle}` is not a valid value for the `$toggle` argument in " +
           "the `hide-visually` mixin. Must be either `hide` or `unhide`.";
    } @else if $toggle == "hide" {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    } @else if $toggle == "unhide" {
        clip: auto;
        clip-path: none;
        height: auto;
        overflow: visible;
        position: static;
        white-space: inherit;
        width: auto;
    }
}

// // ELEMENTS ==============================

// .background-size(VALUE VALUE);
// @mixin background-size($size){
// 	-webkit-background-size : $size;
// 	-moz-background-size : $size;
// 	-o-background-size : $size;
// 	background-size : $size;
// }

//
@mixin calc($property, $value) {
    #{$property}: -webkit-calc(#{$value});
    #{$property}: calc(#{$value});
}

// Carry overs from Compass
//
//
// Triginomery Functions
// --------------------------------------------------------------------------
// Square Root
@function sqrt($r) {
    $x0: 1;
    $x1: $x0;

    @for $i from 1 through 10 {
        $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
        $x0: $x1;
    }
    @return $x1;
}

@function pow($number, $exp) {
    $value: 1;
    @if $exp > 0 {
        @for $i from 1 through $exp {
            $value: $value * $number;
        }
    } @else if $exp < 0 {
        @for $i from 1 through -$exp {
            $value: $value / $number;
        }
    }
    @return $value;
}

@function fact($number) {
    $value: 1;
    @if $number > 0 {
        @for $i from 1 through $number {
            $value: $value * $i;
        }
    }
    @return $value;
}

@function pi() {
    @return 3.14159265359;
}

@function rad($angle) {
    $unit: unit($angle);
    $unitless: $angle / ($angle * 0 + 1);
    // If the angle has 'deg' as unit, convert to radians.
    @if $unit == deg {
        $unitless: $unitless / 180 * pi();
    }
    @return $unitless;
}

@function sin($angle) {
    $sin: 0;
    $angle: rad($angle);
    // Iterate a bunch of times.
    @for $i from 0 through 10 {
        $sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
    }
    @return $sin;
}

@function cos($angle) {
    $cos: 0;
    $angle: rad($angle);
    // Iterate a bunch of times.
    @for $i from 0 through 10 {
        $cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
    }
    @return $cos;
}

@function tan($angle) {
    @return sin($angle) / cos($angle);
}

/**
 * Media Queries
 *
 * Source: https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
 */
//	Setup breakpoints
$xxs: 	( max: 479px );
$xs: 	( min: 480px );
$sm: 	( min: 600px );
$md: 	( min: 800px );
$lg: 	( min: 992px );
$xl: 	( min: 1382px );
$xxl: 	( min: 1824px );
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );
$md-only: ( min: map-get($md, min), max: map-get($lg, min) - 1 );

// Create mixin
@mixin breakpoint($map) {
	$query:;
	@if map-has-key($map, min) {
		$query: append($query, "(min-width: #{map-get($map, min)})")
	}
	@if map-has-key($map, min) and map-has-key($map, max) {
		$query: append($query, "and")
	}
	@if map-has-key($map, max) {
		$query: append($query, "(max-width: #{map-get($map, max)})")
	}
	@media screen and #{$query} { @content; }
}