#{$all-buttons},
.btn {
    appearance: none;
    background-color: $action-color;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: $base-font-family;
    font-size: $base-font-size;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    line-height: 1;
    padding: ($small-spacing * 1.25) ($base-spacing * 2);
    text-align: center;
    text-decoration: none;
    transition: all $base-duration $base-timing;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    letter-spacing: 0.04em;
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2) inset, 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover,
    &:focus {
        background-color: shade($action-color, 10%);
        color: #fff;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
            background-color: $action-color;
        }
    }
}

.btn {
    //  padding: ($base-spacing * .65) ($base-spacing * 1.5);
}

// Button Alternate Sizes
.btn--micro {
    padding: $small-spacing $base-spacing;
    font-size: $base-font-size * 0.9;
    letter-spacing: 0.1em;
}

.btn--puffy {
    font-size: $base-font-size * 1.25;
    border-radius: 6px;
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2) inset, 0 4px 10px rgba(0, 0, 0, 0.2);
}

// Button Alternate Styles
.btn--primary {
    background-color: shade($action-color, 10%);

    &:hover {
        background-color: shade($action-color, 10%);
    }
}

.btn--secondary {
    background-color: #999;

    &:hover {
        background-color: shade(#999, 10%);
        //   box-shadow: inset 0 0 0 5px shade( $diablo-blue, 15% );
    }
}

.btn--alternate {
    background-color: shade($action-color, 10%);

    &:hover {
        background-color: shade($action-color, 10%);
        //  color: $diablo-yellow;
        //  box-shadow: inset 0 0 0 5px shade( $diablo-darkblue-lighter, 15% );
    }
}

.btn--alternate-secondary {
    background-color: shade($action-color, 10%);

    &:hover {
        background-color: shade($action-color, 10%);
        //  box-shadow: inset 0 0 0 5px shade( $diablo-gray, 15% );
    }
}
