// ==========================================================================
// MASTHEAD
// ==========================================================================

#masthead {
    padding-top: $small-spacing;
    padding-bottom: $small-spacing;

    .logo {
        width: 160px;
        margin: 0 auto;
    }

    @include grid-media($grid-md) {
        padding-top: $base-spacing;

        .logo {
            width: 200px;
        }
    }
}
