// ==========================================================================
// PAGES / CREDIT CARD OFFER
// ==========================================================================

#cart-sort--mobile {
    display: block;

    @include grid-media($grid-sm) {
        display: none !important;
    }
}

#card-sort {
    margin-bottom: $base-spacing;
    border-radius: 4px;
    display: none;

    h3 {
        font-size: $base-font-size * 1.15;
        font-weight: 400;
        color: #fff;
        text-align: center;
        padding: $small-spacing * 0.55;
        margin-bottom: 0;
        background: rgba($blue, 0.6);
        text-shadow: 0 1px 1px rgba($purple, 0.3);
    }

    ul {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
    }
    li {
        flex-grow: 1;
        padding: $small-spacing * 0.5;
        color: tint($blue, 40%);
        cursor: pointer;
        text-align: center;
        background: $purple-dark;
        font-weight: 400;
        letter-spacing: 0.02em;

        &:hover {
            color: #fff;
            background: shade($blue, 20%);
        }

        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    @include grid-media($grid-sm) {
        display: block;
    }
}

.cc-offer {
    header {
        padding: $small-spacing;
        grid-area: header;
    }
}

.cc-cta {
    text-align: center;
    background: $purple-light;
    grid-area: cta;
    padding: $small-spacing $base-spacing;

    p {
        font-size: 90%;
        margin: 0;
    }
    .btn {
        margin-bottom: $small-spacing;
    }
}

.cc-image {
    grid-area: image;
    padding: $small-spacing;
    text-align: center;

    img {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}
.cc-copy {
    grid-area: copy;
    padding: 0 $small-spacing 0 ($base-spacing * 2);
    margin-bottom: $base-spacing;

    li {
        list-style: disc;
        font-size: 90%;
        line-height: 1.25em;
        margin-bottom: $small-spacing;
    }
}
.cc-rate {
    grid-area: rate;

    border-top: 1px solid tint($green, 70%);

    dl {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        margin: 0;
        padding: 0;
    }
    dt,
    dd {
        margin: 0;
        font-size: 90%;
        padding: $small-spacing * 0.5;
        width: 50%;
    }
    dt {
        background: tint($green, 70%);
        color: shade($green, 60%);
        border-bottom: 1px solid tint($green, 40%);
        font-weight: 400;
    }
    dd {
        //background: $purple-light;
        border-bottom: 1px solid tint($green, 70%);
    }
}

@include grid-media($grid-sm) {
    .cc-offer {
        display: grid;
        grid-template-columns: 2fr 4fr 3fr;
        grid-template-rows: auto;
        grid-template-areas:
            "header header header"
            "image copy cta"
            "rate rate rate";
    }
    .cc-image {
        padding-top: 0;
        img {
            width: 100%;
        }
    }
    .cc-cta {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: $base-spacing $small-spacing;
    }
    .cc-copy {
        padding-left: $base-spacing;
    }
}

@include grid-media($grid-md) {
    .cc-rate {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;

        dl {
            display: block;
            flex-grow: 1;
        }
        dt,
        dd {
            width: 100%;
        }
        dd {
            border-bottom: 0;
        }
    }
}

// .cc-details {
// 	@include grid-container;
// 	@include grid-collapse;
// }
// .cc-image {
// 	@include grid-column(2);

// 	@include grid-media($grid-sm){

// 	}
// }
// .cc-copy {
// 	@include grid-column(6);
// }
// .cc-cta {
// 	@include grid-column(4);
// }
