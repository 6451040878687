// ==========================================================================
// LAYOUT / SITE
// ==========================================================================

//
// Globals
// --------------------------------------------------------------------------

@-webkit-viewport {
    width: device-width;
}

@-moz-viewport {
    width: device-width;
}

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

html {
    background: #333;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: lighten($base-font-color, 75%);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    background-color: transparent;
    font-family: $base-font-family;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
}

::-moz-selection {
    background-color: lighten($yellow, 20%);
    color: darken($base-font-color, 20%);
    text-shadow: none;
}

::selection {
    background-color: lighten($yellow, 20%);
    color: darken($base-font-color, 20%);
    text-shadow: none;
}

.browserupgrade {
    background: #333;
    padding: $base-spacing;
    text-align: center;
    color: #ccc;

    em {
        font-style: italic;
        color: #fff;
    }

    a {
        color: $action-color;
        font-weight: 700;
        text-decoration: underline;
    }
}

//
// Content Wrappers
// --------------------------------------------------------------------------
.wrapper,
.wrapper--full {
    padding-left: 5%;
    padding-right: 5%;
    width: auto;
    position: relative;
    @include grid-container;

    @include grid-media($grid-sm) {
        padding-left: 2%;
        padding-right: 2%;
    }
}

.wrapper {
    @include grid-media($grid-lg) {
        padding-left: 0;
        padding-right: 0;
        width: 960px;
        margin-left: auto;
        margin-right: auto;
    }

    @include grid-media($grid-xlg) {
        width: 1170px;
    }
}

//
// Site Specifics
// --------------------------------------------------------------------------
#primary-banner {
    background-color: $blue;
    position: relative;
    z-index: 0;
}

.skyline-back,
.skyline-mid {
    width: 100%;
    background-repeat: repeat-x;
    background-position: 50% 100%;
    background-size: auto 200px;
    //background-attachment: fixed;
    bottom: 0;

    @include grid-media($grid-md) {
        background-size: auto 300px;
    }

    @include grid-media($grid-lg) {
        background-size: auto 400px;
    }
}

.skyline-back {
    background-image: url("../img/skyline-back.png");
    background-position: -5% 100%;

    z-index: 1;
}
.skyline-mid {
    background-image: url("../img/skyline-mid.png");
    background-position: -25% 100%;
    z-index: 2;
}

#content-wrapper {
    @include grid-container;
    z-index: 10;
    padding-bottom: $base-spacing * 3;

    @include grid-media($grid-md) {
        padding-top: $base-spacing;

        .module,
        .sort {
            @include grid-column(10);
            @include grid-shift(1);
        }
    }

    @include grid-media($grid-lg) {
        .module,
        .sort {
            @include grid-column(9);
            @include grid-shift(1.5);
        }
    }
}
