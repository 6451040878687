// Custom Font Stacks
$sans-serif: "Roboto", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    Verdana, Tahoma, sans-serif;
$serif: "Roboto Slab", "Hoefler Text", Cambria, "Bitstream Charter", Georgia,
    "Dejavu Serif", serif;

// General Font Fmaily Definitions
$base-font-family: $sans-serif;
$alt-font-family: $serif;

$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 2px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

/**
 * COLORS
 * ==============================
 */
// General Colors
$dark-gray: #555;
$medium-gray: #999;
$light-gray: #ddd;

// Brand Colors
$red: #ef3165;
$green: #5ebc4e;
$blue: #59baea;
$yellow: #fddd00;
$purple-light: #eef5fb;
$purple: #734ea0;
$purple-dark: #161742;

// Font Colors
$base-font-color: desaturate(darken($medium-gray, 20%), 10%);
$action-color: $green;

// Links
$link-color: $action-color;
$link-colorvisited: $link-color;
$link-colorhover: lighten($link-color, 20%);
$link-colorfocus: $action-color;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff !global;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow,
    0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

/**
 * CUSTOM GRIDS
 * ==============================
 */

$neat-grid: (
    columns: 12,
    gutter: 10px
);

$grid-sm: (
    columns: 12,
    gutter: 10px,
    media: 569px
);

$grid-md: (
    columns: 12,
    gutter: 10px,
    media: 819px
);

$grid-lg: (
    columns: 12,
    gutter: 20px,
    media: 1069px
);

$grid-xlg: (
    columns: 12,
    gutter: 20px,
    media: 1300px
);

$grid-xxlg: (
    columns: 12,
    gutter: 20px,
    media: 1700px
);

// $small: 	480px;
// $medium: 	767px;
// $large: 	992px;
// $xlarge: 	1300px;
