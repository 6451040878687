// ==========================================================================
// COMPONENTS / MODALS
// ==========================================================================

.modal {
    text-align: center;
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
}

#lt-modal {
	position: relative;
	border: 1px solid black;
	width: 90%;
	padding: 1em;
	margin: 0 auto;
	background: white;
	top: 20%;
	max-width: 460px;
	@include breakpoint($sm){
		width: 40%;
	}
}

#spinner {
    position: relative;
    top: 40%;
}

.modal #spinner img {
    width: 10%;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 3000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 3000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 3000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
body.loading .modal {
    overflow: hidden; 
}
body.loading .modal {
    display: block;
}
@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
