// ==========================================================================
// FOOTER
// ==========================================================================

#site-footer {
    background: $purple-light;
    text-align: center;
    padding-top: $base-spacing * 3;
    padding-bottom: $base-spacing;

    nav {
        margin-bottom: $base-spacing;
        text-align: center;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        a {
            padding: $small-spacing;
            color: $base-font-color;

            &:hover {
                color: $blue;
            }
        }
    }

    .copyright {
        font-size: $base-font-size * 0.8;
    }
}
