// ==========================================================================
// PAGES / LANDING PAGE
// ==========================================================================

//
// Basic Structure
// --------------------------------------------------------------------------
.module {
    background: #fff;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: $base-spacing;

    > section {
        padding: $base-spacing;
    }

    h2 {
        font-size: $base-font-size * 1.75;
        letter-spacing: -0.06em;
        color: $purple-dark;
        margin-bottom: 0;
    }
    h3 {
        font-size: $base-font-size * 1.25;
        font-weight: 700;
        color: $purple;
    }

    @include grid-media($grid-lg) {
        h2 {
            font-size: $base-font-size * 2;
        }
    }
}

.module-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: $base-spacing $small-spacing $small-spacing;
    background: $yellow;
    text-align: center;

    h1 {
        color: $red;
        margin-bottom: $small-spacing * 0.5;
        font-size: $base-font-size * 1.75;
        font-weight: 700;
        letter-spacing: 0;
    }
    p {
        color: $purple-dark;
        font-size: $base-font-size * 0.8;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    @include grid-media($grid-md) {
        padding: ($base-spacing * 2) $base-spacing $base-spacing;

        h1 {
            font-weight: 400;
            letter-spacing: -0.06em;
            font-size: $base-font-size * 2.75;
        }
        p {
            font-size: $base-font-size * 1.15;
            font-weight: 300;
        }
    }
}

//
// Question/ Answers
// --------------------------------------------------------------------------

.question {
    header {
        text-align: center;
        margin-bottom: $base-spacing;

        p {
            font-size: $base-font-size * 0.8;
        }

        @include grid-media($grid-md) {
            p {
                font-size: $base-font-size * 1;
            }
        }
        @include grid-media($grid-lg) {
            padding-top: $base-spacing;
            margin-bottom: $base-spacing * 1.5;
        }
    }
}

// Hide other steps
#age,
#credit-score,
#card-type,
#results {
    display: none;
}

.answers {
    @include grid-container;
}

.answer {
    @include grid-column(12);
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: $small-spacing;
    margin-bottom: $small-spacing;
    border-radius: 4px;

    img {
        opacity: 0.8;
    }

    .btn {
        width: 100%;
        padding: $small-spacing $small-spacing;
        font-size: $base-font-size * 1.15;

        span {
            display: block;
            font-weight: 400;
            font-size: $base-font-size * 0.75;
            color: shade($action-color, 60%);
        }

        &.btn--span {
            padding: ($small-spacing * 0.98) $small-spacing;
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);

        img {
            opacity: 1;
        }
        .btn {
            background-color: shade($action-color, 10%);
        }
    }

    @include grid-media($grid-sm) {
        @include grid-column(6);
    }

    @include grid-media($grid-md) {
        .btn {
            padding: ($small-spacing * 1.5) $small-spacing;
            font-size: $base-font-size * 1.25;
        }
    }
}

//
// Specific Steps
// --------------------------------------------------------------------------
.step-1 {
    .answer {
        @include grid-column(6);

        // @include grid-media($grid-sm){
        // 	@include grid-column(4);
        // }
    }
}

.step-2 {
    .answer {
        @include grid-column(6);

        @include grid-media($grid-sm) {
            @include grid-column(4);
        }
    }
}

.step-3 {
    .answer {
        @include grid-column(6);
    }

    @include grid-media($grid-sm) {
        .answer {
            @include grid-column(4);
        }
        .off-row {
            @include grid-shift(2);
        }
    }
}

#results {
    @include grid-media($grid-md) {
        form {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

//
// Specific Buttons
// --------------------------------------------------------------------------
#credit-excellent {
    .btn {
        background-color: shade($green, 15%);
    }

    &:hover .btn,
    .btn:hover {
        background-color: shade($green, 25%);
    }
}
#credit-fair {
    .btn {
        background-color: shade($yellow, 15%);
    }

    &:hover .btn,
    .btn:hover {
        background-color: shade($yellow, 20%);
    }
}
#credit-poor {
    .btn {
        background-color: $red;
    }

    &:hover .btn,
    .btn:hover {
        background-color: shade($red, 10%);
    }
}
#credit-notsure {
    .btn {
        background-color: $purple;
    }

    &:hover .btn,
    .btn:hover {
        background-color: shade($purple, 10%);
    }
}

.btn-row {
    text-align: center;
    font-size: 120%;
}

//
// Other Sections
// --------------------------------------------------------------------------
.offer-info {
    background: $purple-dark;
    padding-top: $base-spacing * 3;
    padding-bottom: $base-spacing * 2;
    color: tint($blue, 60%);

    header {
        text-align: center;
    }

    h2 {
        color: $yellow;
    }

    h3 {
        color: #fff;
        padding-top: $base-spacing;
    }

    @include grid-media($grid-md) {
        h3 {
            padding-top: $small-spacing;
        }
        .card-types-section {
            @include grid-container;
            margin-top: $base-spacing * 2;
            background: rgba($blue, 0.1);
        }
        .card-type-section {
            @include grid-column(4);
            padding: $base-spacing;
        }
    }
}
