// ==========================================================================
// BASE / TYPOGRAPHY
// ==========================================================================

body {
    color: $base-font-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
}

//
// Body Text
// --------------------------------------------------------------------------
p {
    margin: 0 0 $small-spacing;
}

p,
li {
    font-weight: 400;
    line-height: $base-line-height;
    margin-bottom: $base-line-height;
    font-size: $base-font-size;

    strong {
        font-weight: 700;
    }
}

//
// Links
// --------------------------------------------------------------------------
a {
    color: $action-color;
    text-decoration: none;
    transition: color $base-duration $base-timing;

    &:active,
    &:focus,
    &:hover {
        color: shade($action-color, 25%);
    }
}

//
// Headers
// --------------------------------------------------------------------------

// Generals
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-family;
    font-size: modular-scale(1);
    line-height: $heading-line-height;
    margin: 0 0 $small-spacing;
}

// Specifics
h1 {
    font-size: 2em;
    letter-spacing: -0.04em;
}

h2 {
    font-size: 1em;
    @include breakpoint($sm){
    		font-size: 1.5em;
    }
}

h3 {
    font-size: 1.35em;
}

h4 {
    //font-size:  1.45em;
    //color: $dark-gray;
}

h4 {
    //font-weight: 700;
    //letter-spacing: -0.04em;
    //font-size:  1em;
}

h5 {
}

//
// Helpers
// --------------------------------------------------------------------------
.sm-caps {
    text-transform: lowercase;
    font-variant: small-caps;
}
