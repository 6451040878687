// ==========================================================================
// LAYOUT / FORMS
// ==========================================================================
$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7);

fieldset {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
}

legend {
    font-weight: 600;
    margin-bottom: $small-spacing / 2;
    padding: 0;
}

label {
    display: inline-block;
    font-weight: 600;
    margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
    display: block;
    font-family: $base-font-family;
    font-size: $base-font-size;
}

#{$all-text-inputs} {
    appearance: none;
    background-color: $base-background-color;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: $_form-box-shadow;
    box-sizing: border-box;
    margin-bottom: $small-spacing;
    padding: ($base-spacing / 2) ($base-spacing / 2);
    transition: border-color $base-duration $base-timing;
    width: 100%;

    &:hover {
        border-color: shade($base-border-color, 20%);
    }

    &:focus {
        border-color: $action-color;
        box-shadow: $_form-box-shadow-focus;
        outline: none;
    }

    &:disabled {
        background-color: shade($base-background-color, 5%);
        cursor: not-allowed;

        &:hover {
            border: $base-border;
        }
    }

    &::placeholder {
        color: tint($base-font-color, 40%);
    }

    @include grid-media($grid-md) {
        padding: ($base-spacing / 1.5) ($base-spacing / 2);
    }
}

textarea {
    resize: vertical;
}

[type="checkbox"],
[type="radio"] {
    display: inline;
    margin-right: $small-spacing / 2;
}

[type="file"] {
    margin-bottom: $small-spacing;
    width: 100%;
}

select {
    margin-bottom: $small-spacing;
    width: 100%;
}

.form-row {
    position: relative;
}

input:not([type="submit"]):not([type="file"]),
textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.6);

    &::placeholder {
        color: $medium-gray;
    }
}

label {
    font-size: 80%;
    font-weight: 700;
    padding-left: $base-spacing / 2;
    text-transform: uppercase;
    letter-spacing: 0.06em;

    .legal & {
        text-transform: none;
        letter-spacing: 0;
        font-size: 90%;
        font-weight: 400;
        padding-left: 0;
    }
}

.legal-check {
    padding-left: 1.5em;
    position: relative;
    span {
        position: absolute;
        left: 0;
        top: 0.25em;
        display: block;
        width: 1em;
        height: 1em;
    }

    [type="checkbox"] {
        font-size: $base-font-size;
        margin: 0;
        padding: 0;
        height: 1.15em;
        width: 1.15em;
    }
}
